<template>
    <div v-loading.fullscreen.lock='loading' element-loading-text="拼命加载中"
  element-loading-background="rgba(255, 255, 255, 0.5)">
        <div style="display:flex;justify-content:space-between;margin: 0 0 20px 0;width:700px">
            <el-input placeholder="请输入新增角色名称" v-model="roleName" class="input-with-select">
                <el-button slot="append" icon="el-icon-plus" @click="addRoleName">新增</el-button>
            </el-input>
            <div>
                <el-input v-model="pageInfo.keyword" style="width:150px;margin-right:10px" placeholder="请输入关键字"></el-input>
                <el-button @click="search" type="primary" icon="el-icon-search">查询</el-button>
            </div>
        </div>
        <el-table
        header-cell-style="text-align:center;background:#DCDFE6;color:gray"
        cell-style="text-align:center"
        :data="tableData"
        border
        style="width:700px;margin:0 0 20px 0">
            <el-table-column
            prop="roleName"
            label="角色名称"
            >
            </el-table-column>
            <el-table-column
            label="操作"
            >
                <template slot-scope="scope">
                    <el-button type="text" @click="edit(scope.row.id)">编辑</el-button>
                    <el-button type="text" @click="remove(scope.row.id)">删除</el-button>
                    <el-button type="text" @click="giveInit(scope.row.id)">分配权限</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
        style="margin-left:150px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.current"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>  
        <el-dialog
        title="编辑修改"
        :visible.sync="changeBox"
        width="20%"
        :before-close="handleClose">
            <span>角色名称：</span><el-input style="width:200px" v-model="role.roleName" placeholder="请输入角色名称"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button @click="changeBox = false">取 消</el-button>
                <el-button type="primary" @click="change">确 定</el-button>
            </span>
        </el-dialog>  
        <el-dialog
            title="分配权限"
            :visible.sync="giveMenuBox"
            width="38%"
            :before-close="dialogBeforeClose">
            <div>
                <el-form v-loading="dialogLoading" ref="form" :model="changeInfo" label-width="80px">
                    <el-form-item label="全选">
                        <el-checkbox @change="changeAll" v-model="all" label="1" >{{''}}</el-checkbox>
                    </el-form-item>
                    <el-form-item
                        v-for="item in menuList"
                        :key="item.id"
                        :label="item.name"
                    >
                        <el-checkbox-group @change="check" v-model="changeInfo.menuIds">
                            <el-checkbox  v-if="item.children.length == 0" :label="item.id">
                                {{ item.name }}
                            </el-checkbox>
                            <el-checkbox
                            @change="check"
                                v-else
                                v-for="child in item.children"
                                :key="child.id"
                                :label="child.id"
                            >
                                {{ child.name }}
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer">
                <el-button @click="dialogBeforeClose">取 消</el-button>
                <el-button type="primary" @click="giveMenu">确 定</el-button>
            </div>
        </el-dialog>
        <div :v-dompurify-html="kk"></div>
    </div>
</template>

<script>
import {
  getList, 
  del, 
  add, 
  updataInit, 
  update, 
  giveMenuInit, 
  allotMenu
} from '@/api/basicManage/role.js'
export default {
    data(){
        return {
            all:[],
            dialogLoading:false,
            loading:false,
            giveMenuBox:false,
            changeBox:false,
            pageInfo:{
                current:'',
                keyword:'',
                pageSize:''
            },
            total:'',
            tableData:[],
            roleName:'',
            role:{
                roleName:'',
                id:''
            },
            menuList:[],
            changeInfo:{
                menuIds:[],
                roleId:''
            }
        }
    },
    watch:{
        changeInfo:{
            handler(newval,old){
                const arr = []
                this.menuList.forEach(item=>{
                    arr.push(item.id)
                    if(item.children.length!=0){
                        item.children.forEach(i=>{
                            arr.push(i.id)
                        })
                    }
                })
                if(newval.menuIds.length==arr.length){
                    this.all=['1']
                }else {
                    this.all=[]
                }
            },
            deep:true,
            immediate:true
        }
    },
    computed:{
        // 分配权限的id数组
        getMenuIds(){
            const arr=this.changeInfo.menuIds
            console.log(arr);
            this.menuList.forEach(item=>{
                if(item.children.length!=0){
                    const id=item.id
                    item.children.forEach(i=>{
                        if(arr.indexOf(i.id)>=0){
                            arr.push(item.id)
                        }
                    })
                }
            })
            const arr2=Array.from(new Set(arr))
            console.log(arr2);
            this.menuList.forEach(item=>{
                if(item.children.length>1&&arr2.indexOf(item.id)>=0){
                    const flag = item.children.every((i)=>{
                        console.log(i.id);
                        console.log(arr2.indexOf(i.id));
                        return arr2.indexOf(i.id)==-1
                    })
                    if(flag){
                        arr2.forEach((j,ind)=>{
                            if(j==item.id){
                                arr2.splice(ind,1)
                            }
                        })
                    }
                }
            })
            return arr2
        }
    },
    mounted(){
        this.getTableData()
    },
    methods:{
        changeAll(){
            const arr = []
            if(this.all[0]==1){
                this.menuList.forEach(item=>{
                    arr.push(item.id)
                    if(item.children.length!=0){
                        item.children.forEach(i=>{
                            arr.push(i.id)
                        })
                    }
                })
                this.changeInfo.menuIds=arr
            }else {
                this.changeInfo.menuIds=arr
            }
            
        },
        dialogBeforeClose(){
            this.changeInfo={
                menuIds:[],
                roleId:''
            }
            this.giveMenuBox=false
        },
        getTableData(){
            this.loading=true
            getList(this.pageInfo).then(res=>{
                console.log(res);
                this.tableData=res.data.values
                this.total=res.data.pageInfo.total
                this.loading=false
            })
        },
        handleSizeChange(val){
            this.pageInfo.pageSize=val
            this.getTableData()
        },
        handleCurrentChange(val){
            this.pageInfo.current=val
            this.getTableData()
        },
        search(){
            this.pageInfo.current=1
            this.getTableData()
        },
        remove(id){
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                del(id).then(res=>{
                    if(res.data.code==200){
                        this.$message({
                            type:'success',
                            message:res.data.message
                        })
                        this.getTableData()
                        if(this.tableData.length<1) this.getTableData()
                    }else{
                        this.$message({
                            type:'warning',
                            message:res.data.message
                        })
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        addRoleName(){
            add(this.roleName).then(res=>{
                if(res.data.code==200){
                    this.$message({
                        type:'success',
                        message:res.data.message
                    })
                    this.roleName=''
                    this.getTableData()
                }else {
                    this.$message({
                        type:'warning',
                        message:res.data.message
                    })
                }
            })
        },
        edit(id){
            this.changeBox=true
            updataInit(id).then(res=>{
                console.log(res);
                if(res.data.code==200){
                    this.role=res.data.data
                }
            })
        },
        change(){
            update(this.role).then(res=>{
                console.log(res)
                if(res.data.code==200){
                    this.$message({
                        type:'success',
                        message:res.data.message
                    })
                    this.getTableData()
                    this.changeBox=false
                }else {
                    this.$message({
                        type:'warning',
                        message:res.data.message
                    })
                }
            })
        },
        giveInit(id){
            this.giveMenuBox=true
            this.changeInfo.roleId=id
            giveMenuInit({id:id}).then(res=>{
                if(res.data.code==200){
                    this.dialogLoading=true
                    this.menuList=res.data.data
                    this.dialogLoading=false
                    console.log(this.menuList);
                    this.menuList.forEach(item=>{
                        if(item.status==1){
                            this.changeInfo.menuIds.push(item.id)
                        }
                        if(item.children.length>=1){
                            item.children.forEach(i=>{
                                if(i.status==1){
                                    this.changeInfo.menuIds.push(i.id)
                                }
                            })
                        }
                    })
                    if(this.changeInfo.menuIds.length==0){
                        this.all=[]
                    }
                }else {
                    this.$message.error(res.data.message)
                }
            })
        },
        giveMenu(){
            this.dialogLoading=true
            allotMenu({roleId:this.changeInfo.roleId,menuIds:this.getMenuIds}).then(res=>{
                if(res.data.code==200){
                    this.$message({
                        type:'success',
                        message:res.data.message
                    })
                    this.dialogLoading=false
                    this.giveMenuBox=false
                    this.changeInfo.menuIds=[]
                    this.getTableData()
                }else {
                    this.$message({
                        type:'warning',
                        message:res.data.message
                    })
                    this.dialogLoading=false
                }
            })
        }
    }
}
</script>

<style scoped lang='scss'>
.input-with-select{
    width: 250px;
    height: 20px;
}
.el-form-item{
    margin: 0;
}
</style>